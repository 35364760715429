<template>
  <div>
    <b-modal
      id="modalHistoricoList"
      ref="modalHistoricoList"
      button-size="sm"
      ok-only
      ok-title="Ok"
      size="xl"
      @hidden="fecharModal"
    >
      <div slot="modal-title">
        <b-row>
          <b-col>
            <h4>Histórico {{ rotina == 'situação fiscal' ? '' : 'de' }} {{ rotina }} {{ subRotinas }} - {{ empresa.razaoSocial }} </h4>
          </b-col>
        </b-row>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela você encontra o histórico {{ rotina == 'situação fiscal' ? 'das' : 'de' }} {{ textoRotina() }} emitidas pelo Monitor a partir de {{ desDe }}.
        </fieldset>
      </div>
      <b-row
        class="mb-1 mr-0 justify-content-end mb-1"
      >
        <b-col
          cols="auto"
          class="p-0 d-flex align-items-center"
        >
          <button
            v-b-tooltip.hover
            style="font-size: 11.5px; padding: .40rem;"
            :title="`Realizar o download das ${textoRotina()} selecionadas`"
            class="btn btn-sm btn-outline-primary mr-1"
            @click="baixarDocumentos()"
          >
            <feather-icon
              icon="DownloadIcon"
              class="text-primary"
              size="18"
            />
            Baixar em lote
          </button>
          <button
            v-b-tooltip.hover
            style="font-size: 11.5px; padding: .40rem; margin-right: 1rem;"
            :title="`Enviar por WhatsApp as ${textoRotina()} selecionadas`"
            class="btn btn-sm btn-outline-primary"
            @click="openWhatsApp()"
          >
            <font-awesome-icon
              icon="fa-brands fa-whatsapp"
              class="text-primary"
              style="font-size: 18px"
            />
            Enviar por WhatsApp
          </button>
          <button
            v-b-tooltip.hover
            style="font-size: 11.5px; padding: .40rem;"
            :title="`Enviar por e-mail as ${textoRotina()} selecionadas`"
            class="btn btn-sm btn-outline-primary"
            @click="openModalEnviarEmail()"
          >
            <feather-icon
              icon="SendIcon"
              class="text-primary"
              size="18"
            />
            Enviar por e-mail
          </button>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col>
          <busca-input
            v-model="buscaDigitada"
            placeholder="Buscar por data"
            fonte-menor
            @input="buscar"
            @clean="cleanInput"
          />
        </b-col>
        <b-col cols="4">
          <filtro-rapido
            :filtros="filtrosRapidos"
            @selecionado="aplicarFiltroRapido($event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5>Histórico</h5>
        </b-col>
        <b-col class="text-right">
          <h5>Total de {{ listaHistorico.total }} {{ listaHistorico.total === 1 ? 'item' : 'itens' }}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="listaHistorico.valores"
            :somente-visualizacao="false"
            :customizar-botoes="true"
            :colunas="colunas"
            :tem-selecao="true"
            :responsive="true"
            :sorting-local="true"
            @selected-items="getLinhasSelecionadas"
            @changed-sort="aplicarOrdemBy"
          >
            <template #visualizacao="{slotScope: {valor, key}}">
              <b-badge
                v-if="valor.toLowerCase() == 'regular'"
                variant="light-success"
                class="badge-size-p"
              >
                Regular
              </b-badge>
              <b-badge
                v-else-if="valor.toLowerCase() == 'irregular'"
                variant="light-danger"
                class="badge-size-p"
              >
                Irregular
              </b-badge>
              <b-badge
                v-else-if="valor.toLowerCase() == 'procuracao'"
                variant="light-warning"
                class="badge-size-p"
              >
                Procuração
              </b-badge>
              <b-badge
                v-else-if="valor.toLowerCase() == 'motivo'"
                variant="light-primary"
                class="badge-size-p"
              >
                Motivo
              </b-badge>
              <b-badge
                v-else-if="valor.toLowerCase() == 'instabilidade'"
                variant="light-primary"
                class="badge-size-p"
              >
                Instabilidade
              </b-badge>
              <b-badge
                v-else-if="key === 'situacao'"
                variant="light-warning"
                class="badge-size-p"
              >
                {{ valor }}
              </b-badge>
              <div v-else>
                {{ valor }}
              </div>
            </template>
            <template #botoes-customizados="{slotScope: {item}}">
              <div
                class="d-inline-flex"
                style="gap: 0.5rem"
              >
                <b-btn
                  v-if="item.id"
                  v-b-tooltip.hover="{ boundary: 'window' }"
                  title="Visualizar"
                  class="btn-none icone-acao m-0 p-0"
                  variant="none"
                  @click="openVisualizacaoArquivo(item)"
                >
                  <font-awesome-icon
                    :icon="faExternalLinkAlt"
                    class="text-primary"
                  />
                </b-btn>
                <b-btn
                  v-if="item.id"
                  v-b-tooltip.hover="{ boundary: 'window' }"
                  title="Download"
                  class="btn-none icone-acao m-0 p-0"
                  variant="none"
                  @click="baixarDocumentos([item.id])"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="text-primary"
                    size="17"
                  />
                </b-btn>
                <b-btn
                  v-if="item.id && tipo"
                  v-b-tooltip.hover="{ boundary: 'window' }"
                  title="Observações"
                  variant="none"
                  class="btn-none rounded-pill m-0 icone-acao p-0"
                  @click="openModalObservacao(item)"
                >
                  <font-awesome-icon
                    :icon="faMessage"
                    :class="{ 'text-warning': item.temObservacoesNaoLidas, 'text-primary': !item.temObservacoesNaoLidas }"
                  />
                </b-btn>
                <b-btn
                  v-if="item.id"
                  v-b-tooltip.hover="{ boundary: 'window' }"
                  title="Enviar por e-mail"
                  variant="none"
                  class="btn-none icone-acao m-0 p-0"
                  @click="openModalEnviarEmail([item.id])"
                >
                  <feather-icon
                    icon="SendIcon"
                    class="text-primary"
                    size="15"
                  />
                </b-btn>
                <button
                  v-if="item.id"
                  v-b-tooltip.hover="{ boundary: 'window' }"
                  title="Enviar por WhatsApp"
                  style="font-size: 11.5px; padding: .1rem"
                  class="btn btn-sm"
                  @click="openWhatsApp([item.id])"
                >
                  <font-awesome-icon
                    icon="fa-brands fa-whatsapp"
                    class="text-primary"
                    style="font-size: 18px"
                  />
                </button>
              </div>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
    </b-modal>
    <visualizar-arquivo
      v-if="mostrarArquivo && arquivo.url"
      :url="arquivo.url"
      @hidden="fecharArquivo()"
    />
    <modal-observacao
      v-if="tipo != ''"
      ref="modalObservacao"
      :value="observacaoSelecionada"
      :id-registro="empresa.empresaId"
      :tipo="tipo"
      :api="api"
      :notificar="{ sucesso: notficarSucesso, erro: notificarErro }"
    />
    <envio-email
      v-if="emailId > 0"
      :ref="`modalEnvioEmail${emailId}`"
      :title="`Enviar ${rotina} - ${empresa.razaoSocial}`"
      :sugestao="empresa.emailEmpresa"
      :sugestao-email-adicional="empresa.emailAdicionalEmpresa"
      @enviarEmail="enviarEmail($event)"
      @hide="fecharEmail"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </div>
</template>
<style lang="scss">

#modalHistoricoList {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: center !important;

  .modal-xl {
    display: flex;
    width: calc(50rem);
  }
}
</style>
<script>
import moment from 'moment'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faMessage } from '@fortawesome/free-regular-svg-icons'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ModalCndRfbPgfnView',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    VisualizarArquivo: () => import('@pilar/components/visualizar-arquivo/VisualizarArquivo.vue'),
    ModalObservacao: () => import('@/app/shared/components/modal-observacao/ModalObservacao.vue'),
    EnvioEmail: () => import('@/app/shared/components/envio-email/EnvioEmail.vue'),
    BuscaInput: () => import('@pilar/components/busca-input/BuscaInput.vue'),
    FiltroRapido: () => import('@pilar/components/visualizacao-base//components/filtro-rapido/FiltroRapido.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
  },
  props: {
    rotina: {
      type: String,
      default: null,
    },
    subRotinas: {
      type: String,
      default: null,
    },
    desDe: {
      type: String,
      default: null,
    },
    filtrosRapidos: {
      type: Array,
      default: () => [],
    },
    empresa: {
      type: Object,
      required: true,
    },
    api: {
      type: Object,
      required: true,
    },
    tipo: {
      type: String,
      default: '',
    },
    colunaHistorico: {
      type: Array,
      default: () => [],
    },
    filtroByHistorico: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      faExternalLinkAlt,
      faMessage,
      listaHistorico: [],
      mostrarArquivo: false,
      arquivo: {},
      emailId: 0,
      emailIds: [],
      observacaoSelecionada: {},
      linhasSelecionadas: [],
      buscaDigitada: '',
      filtroRapidoSelecionados: '',
      ordemBySelecionados: '',
      filtroAtivo: {
        filtroBy: [{ colunas: this.filtroByHistorico, valor: null }],
        ordemBy: [{ coluna: 'Id', tipo: 'desc' }],
      },
      colunas: this.colunaHistorico,
      isLoading: false,
    }
  },
  created() {
    this.filtroAtivo.filtroBy = [...this.filtroAtivo.filtroBy, { valor: this.filtroRapidoSelecionados, colunas: ['Situacao'] }]
  },
  mounted() {
    document.addEventListener('keydown', this.fecharEscKey)
  },
  methods: {
    fecharModal() {
      this.$emit('hide')
    },
    fecharArquivo() {
      this.mostrarArquivo = false
      this.arquivo = {}
    },
    fecharEmail() {
      this.emailId = 0
      this.emailIds = []
    },
    fecharEscKey(event) {
      if (event.key === 'Escape') {
        this.$swal.close()
      }
    },
    abrirModal() {
      this.linhasSelecionadas = []
      this.buscaDigitada = ''
      this.filtroRapidoSelecionados = ''
      this.buscar(this.filtroAtivo)
      this.$refs.modalHistoricoList.show()
    },
    notficarSucesso(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-check',
          variant: 'success',
        },
      })
    },
    notificarErro(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: msg,
          icon: 'fa-solid fa-circle-exclamation',
          variant: 'danger',
        },
      })
    },
    aplicarFiltroRapido(item) {
      this.filtroRapidoSelecionados = item ? item.valor : ''
      this.buscar()
    },
    aplicarOrdemBy(item) {
      this.ordemBySelecionados = item
      this.buscar()
    },
    buscar() {
      if (this.filtroAtivo.filtroBy && this.filtroAtivo.filtroBy.length > 0) {
        if (this.ordemBySelecionados) {
          this.filtroAtivo.ordemBy[0].tipo = this.ordemBySelecionados[0].tipo
          this.filtroAtivo.ordemBy[0].coluna = this.ordemBySelecionados[0].coluna
        }
        this.filtroAtivo.filtroBy[0].valor = this.buscaDigitada
        this.filtroAtivo.filtroBy[1].valor = this.filtroRapidoSelecionados
      }
      this.api.getHistorico(this.tipo, this.empresa.empresaId, this.filtroAtivo)
        .then(payload => {
          if (payload.data < 1) {
            this.listaHistorico = `Nenhuma ${this.rotina} encontrada`
          } else {
            this.listaHistorico = payload.data
          }
        })
    },
    cleanInput() {
      this.buscaDigitada = ''
      this.buscar()
    },
    getLinhasSelecionadas(linhas) {
      this.linhasSelecionadas = linhas
    },
    baixarDocumentos(id) {
      if (id || this.linhasSelecionadas.length > 0) {
        this.isLoading = true
        this.api.baixarSelecionados(id || this.linhasSelecionadas)
          .then(payload => {
            this.isLoading = false
            window.open(payload.data)
            this.$sucesso(`Download da ${this.rotina} finalizado.`)
          })
          .catch(err => {
            this.isLoading = false
            this.$erro(err.message || 'Ocorreu um erro no download.')
            throw err
          })
      } else {
        this.$erro(`Não há nenhuma ${this.rotina} selecionada para download!`)
      }
    },
    openWhatsApp(id) {
      if (id > 0) {
        this.montarMensagemWhatsApp(id)
      } else if (this.linhasSelecionadas.length > 0) {
        this.montarMensagemWhatsApp(this.linhasSelecionadas)
      } else {
        this.$erro(`Não há nenhuma ${this.rotina} selecionada para envio!`)
      }
    },
    montarMensagemWhatsApp(ids) {
      const dias = 15
      this.isLoading = true
      const mensagem = `Olá *${this.empresa.razaoSocial}* segue o link para download do(s) seguintes(s) documento(s):\r\n\r\n${this.informativoSelecionado(ids)}\r\nApós baixar, solicitamos que faça a conferência dos dados!\r\n\r\nO link para download ficará disponível até ${moment().add(dias, 'days').format('DD/MM/YYYY')}\r\n`
      this.api.GetUrlWhatsApp(ids)
        .then(payload => {
          this.isLoading = false
          this.$swal({
            width: 680,
            title: '<div class="text-secondary">Mensagem</div>',
            html: `<div class="text-primary">${(mensagem.replace(/\r\n/g, '<br>'))} <a href="${payload.data}" target="_blank" class="text-secondary" style="text-decoration: underline;">Link do arquivo</a></div>`,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Copiar link',
            denyButtonText: 'WhatsApp',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
            customClass: {
              denyButton: 'btn btn-primary mr-50',
              confirmButton: 'btn btn-outline-primary mr-50',
              cancelButton: 'btn btn-outline-danger mr-50',
            },
            allowOutsideClick: true,
          }).then(result => {
            if (result.isConfirmed) {
              this.$swal('Copiado!', '', 'success')
              navigator.clipboard.writeText(payload.data)
            } else if (result.isDenied) {
              const url = `https://wa.me/?text=${encodeURIComponent(`${mensagem}${payload.data}`)}`
              window.open(url)
            }
          })
        })
        .catch(err => {
          this.isLoading = false
          this.$erro(`Erro ao encaminhar mensagem via WhatsApp: ${err}.`, err)
        })
    },
    informativoSelecionado(ids) {
      let mensagemItens = ''
      for (let i = 0; i < ids.length; i += 1) {
        const elemento = this.listaHistorico.valores.find(item => item.id === ids.sort((a, b) => b - a)[i])
        mensagemItens += `${this.rotina} ${this.subRotinas ? this.subRotinas : ''} ${this.getSituacao(elemento.situacao)} consultada em ${elemento.ultimaConsulta ? elemento.ultimaConsulta : elemento.consultadoEm} ${elemento.validade ? 'válida até' : ''} ${elemento.validade ? elemento.validade : ''}\r\n`
      }
      return mensagemItens
    },
    getSituacao(situacao) {
      const situacaoMap = {
        regular: 'Regular',
        irregular: 'Irregular',
        procuracao: 'Procuração',
        motivo: 'Motivo',
      }
      return situacaoMap[situacao.toLowerCase()] ? situacaoMap[situacao.toLowerCase()] : situacao
    },
    openVisualizacaoArquivo(iten) {
      if (iten.mensagemErro === undefined || iten.mensagemErro === '') {
        this.api.getURL(iten.id)
          .then(payload => {
            this.arquivo.url = payload.data
            this.mostrarArquivo = true
          })
          .catch(err => {
            this.$erro(`Não foi possível abrir a visualização das ${this.rotina}.`, err)
          })
      } else {
        this.arquivo.url = `<div>${iten.mensagemErro}</div>`
        this.mostrarArquivo = true
      }
    },
    openModalEnviarEmail(id) {
      if (id > 0) {
        this.emailId = id
        setTimeout(() => this.$refs[`modalEnvioEmail${this.emailId}`].$refs.modalEnviarEmail.show(), 100)
      } else if (this.linhasSelecionadas.length > 0) {
        this.emailIds = this.linhasSelecionadas
        this.emailId = this.linhasSelecionadas.join('')
        setTimeout(() => this.$refs[`modalEnvioEmail${this.emailId}`].$refs.modalEnviarEmail.show(), 100)
      } else {
        this.$erro(`Não há nenhuma ${this.rotina} selecionada para envio!`)
      }
    },
    openModalObservacao(documento) {
      this.msgSelecionada = documento
      this.$refs.modalObservacao.show()
    },
    enviarEmail(itens) {
      if (itens.emailsPara.length === 0 && itens.emailsCc === 0) {
        this.$erro('Informe ao menos um e-mail')
      } else {
        this.isLoading = true
        this.api
          .notificaHistorico(this.empresa.razaoSocial, itens.emailsPara, itens.emailsCc, itens.mensagensAdicional, itens.definirEmailPadroes, (this.emailIds.length > 0 ? this.emailIds : this.emailId))
          .then(() => {
            this.isLoading = false
            this.$sucesso(`${this.rotina} enviada com sucesso`)
          })
          .catch(err => {
            this.isLoading = false
            this.$erro(err.message || 'Não foi possível enviar as notificações.')
            throw err
          })
      }
    },
    textoRotina() {
      return this.rotina === 'situação fiscal' ? 'situações fiscais' : `${this.rotina}s`
    },
  },
}
</script>
